import React from 'react'
import { Button } from '../../../atoms/Button'
import {
  ButtonsWrapper,
  FamilyMainSvg,
  ImageWrapper,
  InfoContainer,
  Wrapper
} from './FirstBlock.styles'
import { Content, DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { Grid } from '../../../atoms/Grid'
import { SeoPriceForTitleBlock } from '../../../atoms/SeoPriceForTitleBlock'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

const FirstBlock = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <InfoContainer>
              <TitleWrapper>
                <Title.H1>
                  Подбор и консультация семейного психолога онлайн&nbsp;— помощь
                  с&nbsp;отношениями в&nbsp;семье
                </Title.H1>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Консультация с&nbsp;семейным психологом поможет наладить
                  отношения в&nbsp;семье, найти общий язык, пережить трудности,
                  восстановить баланс или бережно завершить отношения.
                </Text.Large>
                <Text.Large semiBold>
                  Семейные психологи YouTalk — высококлассные специалисты,
                  которые помогут достичь ваших целей в&nbsp;отношениях.
                </Text.Large>
                <SeoPriceForTitleBlock />
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                  href="/wizard/"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <FamilyMainSvg />
            </ImageWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default FirstBlock
