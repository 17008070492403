import styled from 'styled-components'
import { Title } from '../../../atoms/Title'
import { size } from '../../../constants'

export const ListImage = styled.img`
  width: 78px;
  height: 78px;

  @media (max-width: ${size.xs}) {
    width: 64px;
    height: 64px;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  gap: 16px;

  ${Title.H2asH1styles} {
    color: white;
  }
`

export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const InfoContainer = styled.div``
