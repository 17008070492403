import React from 'react'
import { Button } from '../../../atoms/Button'
import {
  ButtonsWrapper,
  Content,
  ImageWrapper,
  InfoContainer,
  LadySvg,
  Wrapper
} from './LastBlock.styles'
import { DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'
import { Grid } from '../../../atoms/Grid'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

const LastBlock = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <InfoContainer>
              <TitleWrapper>
                <Title.DivAsH1styles>
                  Дайте себе возможность получить поддержку и помощь, которую вы
                  заслуживаете
                </Title.DivAsH1styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold>
                  Вы всегда можете задать нам вопросы или почитать статьи
                  в&nbsp;блоге, чтобы успокоиться и развеять сомнения. Гайды и
                  чек-листы помогут подготовиться к&nbsp;первой сессии, а
                  психолог будет поддерживать на&nbsp;пути к&nbsp;разрешению
                  проблем.
                </Text.Large>
              </DescriptionWrapper>
              <ButtonsWrapper>
                <Button.NewPrimary
                  onClick={GAEvent.openSemeinyiPageFirstScreen}
                  size="large"
                  type="link"
                  href="/wizard/"
                >
                  Подобрать психолога
                </Button.NewPrimary>
              </ButtonsWrapper>
            </InfoContainer>
            <ImageWrapper>
              <LadySvg />
            </ImageWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default LastBlock
