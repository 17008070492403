import DescriptionBlock from './DescriptionBlock'
import React from 'react'
import laptop from '../../../static/img/family-seo/laptop.svg'

const NextSessions = () => (
  <DescriptionBlock
    backgroundColor="white"
    description="Психолог будет задавать вопросы о&nbsp;проблеме, применять разные техники и упражнения, подсвечивать, что в&nbsp;вашей семье запускает и подтапливает подобные отношения. В зависимости от&nbsp;подхода психолога процесс будет немного различаться, но суть остается одинаковой: каждую консультацию вы будете пытаться сделать шаг к&nbsp;здоровой коммуникации и взаимопониманию."
    image={laptop}
    subtitle="На&nbsp;следующих консультациях"
  />
)
export default NextSessions
