import React from 'react'
import {
  Container,
  FirstCol,
  NumbersInfoContainer,
  NumbersInfoDescription,
  NumbersInfoTitle,
  ParagraphContainer,
  ParagraphImgWrapper,
  ParagraphTextWrapper,
  SecondCol,
  StarSVG,
  TitleWrapper,
  Wrapper
} from './WhyHelp.styles'
import { Grid } from '../../../atoms/Grid'
import { Title } from '../../../atoms/Title'

const WhyHelp = () => (
  <Wrapper>
    <section>
      <Grid>
        <Container>
          <FirstCol>
            <TitleWrapper>
              <Title.H2asH1styles>
                Почему стоит получить помощь психолога по&nbsp;семейным делам
                в&nbsp;YouTalk?
              </Title.H2asH1styles>
            </TitleWrapper>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Наши психологи имеют соответствующее образование и опыт работы с
                паническими атаками и прошли серьезный отбор
                на&nbsp;компетентность.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                Все наши психологи имеют профессиональное образование и опыт
                работы с&nbsp;семьями. Они глубоко понимают проблемы,
                возникающие в&nbsp;семьях, и помогут найти эффективные решения.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Консультации в YouTalk анонимны. Нам важен лишь ваш контакт
                для&nbsp;связи с&nbsp;психологом и администратором. Всю
                остальную личную информацию вы не&nbsp;обязаны раскрывать, если
                не&nbsp;хотите.
              </ParagraphTextWrapper>
            </ParagraphContainer>
            <ParagraphContainer>
              <ParagraphImgWrapper>
                <StarSVG />
              </ParagraphImgWrapper>
              <ParagraphTextWrapper>
                {/* eslint-disable-next-line max-len */}
                Точный подбор. Наши специалисты по подбору — квалифицированные
                психологи. Они уточнят ваш запрос и подберут специалиста,
                опираясь на&nbsp;десятки критериев. В YouTalk 97% клиентов
                находят своего психолога с&nbsp;первого раза.
              </ParagraphTextWrapper>
            </ParagraphContainer>
          </FirstCol>
          <SecondCol>
            <NumbersInfoContainer>
              <NumbersInfoTitle>280+</NumbersInfoTitle>
              <NumbersInfoDescription>
                психологов в&nbsp;нашей команде
              </NumbersInfoDescription>
            </NumbersInfoContainer>
            <NumbersInfoContainer>
              <NumbersInfoTitle>7 лет</NumbersInfoTitle>
              <NumbersInfoDescription>
                средний опыт работы специалистов
              </NumbersInfoDescription>
            </NumbersInfoContainer>
            <NumbersInfoContainer>
              <NumbersInfoTitle>22 434</NumbersInfoTitle>
              <NumbersInfoDescription>
                человек получили поддержку за&nbsp;год
              </NumbersInfoDescription>
            </NumbersInfoContainer>
          </SecondCol>
        </Container>
      </Grid>
    </section>
  </Wrapper>
)

export default WhyHelp
