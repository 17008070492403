import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background: #e9f2fd;
  padding-bottom: 72px;
  padding-top: 72px;

  @media (max-width: ${size.md}) {
    padding-bottom: 48px;
    padding-top: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
`

export const Container = styled.div`
  display: flex;
  background: #e9f2fd;
  padding-top: 72px;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 792px;
  padding: 40px 16px;
  border-radius: 24px;
  border: 1px solid #dedede;
  background: white;

  @media (max-width: ${size.lg}) {
    width: 632px;
    padding: 40px 24px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
    padding: 40px 16px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 16px;
`

export const IconWrapper = styled.div`
  text-align: center;
  margin-top: -110px;
  margin-bottom: 8px;
`

export const ButtonWrapper = styled.div`
  ${Button.NewPrimary} {
    margin: auto;

    @media (max-width: ${size.xs}) {
      width: 100%;
    }
  }
`
