import React from 'react'
import ruporSVG from '../../../../static/img/family-seo/rup.svg'
import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import {
  ButtonWrapper,
  Container,
  Content,
  IconWrapper,
  TextWrapper,
  Wrapper
} from './HowToFind.styles'
import { Event as GAEvent } from '../../../components/GA'
import { Grid } from '../../../atoms/Grid'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

export const RuporSVG = styled.img.attrs(() => ({
  src: ruporSVG
}))``

const HowToFind = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Container>
            <Content>
              <IconWrapper>
                <RuporSVG />
              </IconWrapper>
              <TextWrapper>
                <Title.H3>
                  Как найти и выбрать психолога по&nbsp;семейным отношениям?
                </Title.H3>
                <Text.Large semiBold>
                  Выбор семейного психолога может быть сложным: важно найти
                  специалиста, который имеет опыт работы с&nbsp;семьями и
                  подходит именно вам. В&nbsp;YouTalk работают только
                  квалифицированные психологи по&nbsp;семейным отношениям
                  с&nbsp;опытом работы от&nbsp;3&nbsp;лет, вы точно попадете
                  к&nbsp;компетентному специалисту, выбрав его в&nbsp;YouTalk.
                </Text.Large>
                <Text.Large semiBold>
                  Вы можете выбрать психолога самостоятельно, или наши
                  специалисты по&nbsp;подбору помогут вам выбрать подходящего
                  психотерапевта на&nbsp;основе ваших пожеланий и предпочтений.
                </Text.Large>
              </TextWrapper>
            </Content>
            <ButtonWrapper>
              <Button.NewPrimary
                href="/wizard/"
                size="large"
                type="link"
                onClick={GAEvent.openSemeinyiPageFirstScreen}
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonWrapper>
          </Container>
        </Grid>
      </section>
    </Wrapper>
  )
}

export default HowToFind
