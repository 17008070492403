import React from 'react'
import electricSvg from '../../../../static/img/family-seo/electric.svg'
import heartbreakSvg from '../../../../static/img/family-seo/heartbreak.svg'
import messageSvg from '../../../../static/img/family-seo/message.svg'
import peopleSvg from '../../../../static/img/family-seo/people.svg'
import {
  Content,
  ListImage,
  ListItem,
  ListItemText,
  ListWrapper,
  TitleWrapper,
  Wrapper
} from './ThirdBlock.styles'
import { Grid } from '../../../atoms/Grid'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

const ThirdBlock = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <TitleWrapper>
              <Title.H2>
                Когда особенно может быть полезна помощь семейного психолога?
              </Title.H2>
            </TitleWrapper>
            <ListWrapper>
              <ListItem>
                <ListImage src={electricSvg} />
                <ListItemText>
                  <Title.H4>Конфликты в семье</Title.H4>
                  <Text.Large semiBold>
                    Психолог по семейным вопросам может помочь вам найти
                    компромисс и научиться эффективно решать конфликты.
                  </Text.Large>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListImage src={messageSvg} />
                <ListItemText>
                  <Title.H4>Непонимание и плохая коммуникация</Title.H4>
                  <Text.Large semiBold>
                    Семейный психолог может научить эффективному взаимодействию
                    в&nbsp;семье и помочь услышать и понять друг друга.
                  </Text.Large>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListImage src={heartbreakSvg} />
                <ListItemText>
                  <Title.H4>Сложности в&nbsp;отношениях</Title.H4>
                  <Text.Large semiBold>
                    Семейный психолог может помочь разобраться в&nbsp;причинах и
                    научиться строить здоровые и гармоничные отношения.
                  </Text.Large>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListImage src={peopleSvg} />
                <ListItemText>
                  <Title.H4>Изменения в семье</Title.H4>
                  <Text.Large semiBold>
                    Семейный психолог может помочь вам адаптироваться к
                    изменениям (например, разводу, переезду, появлению нового
                    члена семьи) и справиться с эмоциональным напряжением.
                  </Text.Large>
                </ListItemText>
              </ListItem>
            </ListWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default ThirdBlock
