import React from 'react'
import cloudSvg from '../../../../static/img/family-seo/cloud.svg'
import loveSvg from '../../../../static/img/family-seo/love.svg'
import zipSvg from '../../../../static/img/family-seo/zip.svg'
import { Content, DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Grid } from '../../../atoms/Grid'
import {
  InfoContainer,
  ListImage,
  ListItem,
  ListWrapper,
  Wrapper
} from './SecondBlock.styles'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

const SecondBlock = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <InfoContainer>
              <TitleWrapper color="white">
                <Title.H2asH1styles>
                  Когда нужна помощь семейного психолога?
                </Title.H2asH1styles>
              </TitleWrapper>
              <DescriptionWrapper>
                <Text.Large semiBold color="#fff">
                  Семейный психолог может помочь вам, если возникли проблемы
                  в&nbsp;отношениях с партнёром, детьми, родителями или другими
                  близкими.
                </Text.Large>
              </DescriptionWrapper>
            </InfoContainer>
            <ListWrapper>
              <ListItem>
                <ListImage src={cloudSvg} />
                <Text.Large semiBold color="#fff">
                  Это могут быть конфликты, проблемы с&nbsp;общением, измена,
                  развод или любые другие ситуации, которые могут повлиять
                  на&nbsp;качество ваших отношений.
                </Text.Large>
              </ListItem>
              <ListItem>
                <ListImage src={zipSvg} />
                <Text.Large semiBold color="#fff">
                  Или у&nbsp;вас может быть чувство, что отношения застряли
                  на&nbsp;месте и вы не&nbsp;знаете, как продвинуться дальше.
                </Text.Large>
              </ListItem>
              <ListItem>
                <ListImage src={loveSvg} />
                <Text.Large semiBold color="#fff">
                  Семейный психолог поможет вам наладить общение, разобраться
                  в&nbsp;причинах проблем и найти эффективные способы их
                  решения.
                </Text.Large>
              </ListItem>
            </ListWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default SecondBlock
