import familyMainSvg from '../../../../static/img/family-seo/family-main.svg'
import styled from 'styled-components'
import { Button } from '../../../atoms/Button'
import { size } from '../../../constants'

export const FamilyMainSvg = styled.img.attrs(() => ({
  src: familyMainSvg
}))`
  width: 588px;
  height: 588px;

  @media (max-width: ${size.md}) {
    width: 468px;
    height: 468px;
  }

  @media (max-width: ${size.xs}) {
    width: 350px;
    height: 350px;
  }

  @media (max-width: ${size.xxs}) {
    width: 234px;
    height: 234px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: #e9f2fd;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.sm}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
      margin-bottom: 24px;
    }
  }
`

export const InfoContainer = styled.div``
