import styled from 'styled-components'
import { Title } from '../../../atoms/Title'
import { size } from '../../../constants'

export const ImageSVG = styled.img`
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    width: 441px;
    height: 364px;
  }

  @media (max-width: ${size.sm}) {
    width: 273px;
    height: 238px;
  }
`

export const TitleWrapper = styled.div`
  ${Title.H1} {
    color: ${(props) => props.color ?? 'black'};
  }
  padding-top: 72px;

  @media (max-width: ${size.md}) {
    padding-top: 48px;
  }

  @media (max-width: ${size.sm}) {
    padding-top: 32px;
  }
`

export const Content = styled.div`
  padding: 36px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
  }

  @media (max-width: ${size.md}) {
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
  background-color: ${(props) => props.color};
`

export const InfoContainer = styled.div`
  color: ${(props) => props.color ?? 'black'};
  margin-bottom: 32px;

  > * {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`
