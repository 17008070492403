import styled from 'styled-components'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'
import { size } from '../../../constants'

import { color } from '../../../styles/vars/colors'

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: black;
    text-align: center;
  }
`

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    gap: 32px;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const ListImage = styled.img`
  width: 104px;
  height: 104px;

  @media (max-width: ${size.xs}) {
    width: 64px;
    height: 64px;
  }
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  gap: 16px;
  flex-basis: 23%;
  align-items: center;

  @media (max-width: ${size.lg}) {
    flex-basis: 48%;
  }

  @media (max-width: ${size.sm}) {
    flex-basis: 98%;
  }
`

export const ListItemText = styled.div`
  gap: 8px;

  ${Text.Large} {
    width: 100%;
    text-align: left;
  }

  ${Title.H4} {
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
  }
`

export const Wrapper = styled.div`
  background-color: ${color.text.white};
`

export const InfoContainer = styled.div``
