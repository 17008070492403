import React from 'react'
import { Content, DescriptionWrapper, TitleWrapper } from '../Common.styles'
import { Grid } from '../../../atoms/Grid'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'
import { Wrapper } from './SearchTitle.styles'

const SearchTitle = () => {
  return (
    <Wrapper>
      <section>
        <Grid>
          <Content>
            <TitleWrapper color="white">
              <Title.H2asH1styles>
                Подберите специалиста и запишитесь на&nbsp;консультацию уже
                сейчас
              </Title.H2asH1styles>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold color="fff">
                Решиться на&nbsp;терапию, особенно семейную, может быть
                не&nbsp;просто. Специалисты YouTalk поддержат вас на этом пути:
                вы можете заполнить анкету, и специалист по&nbsp;подбору найдет
                для вас наиболее подходящего психолога, или вы можете выбрать
                его самостоятельно, через&nbsp;наш алгоритм. Гайды и чек-листы
                помогут подготовиться к&nbsp;первой сессии, а психолог будет
                поддерживать на&nbsp;пути к&nbsp;разрешению семейных проблем.
              </Text.Large>
              <Text.Large semiBold color="fff">
                Дайте себе возможность получить поддержку и помощь, которую вы
                заслуживаете.
              </Text.Large>
            </DescriptionWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default SearchTitle
