import React from 'react'
import {
  Content,
  ImageSVG,
  ImageWrapper,
  InfoContainer,
  TitleWrapper,
  Wrapper
} from './DescriptionBlock.styles'
import { Grid } from '../../../atoms/Grid'
import { Text } from '../../../atoms/Text'
import { Title } from '../../../atoms/Title'

const DescriptionBlock = ({
  title,
  subtitle,
  description,
  image,
  backgroundColor
}) => {
  return (
    <Wrapper color={backgroundColor}>
      <section>
        <Grid>
          {title && (
            <TitleWrapper>
              <Title.H2asH1styles>{title}</Title.H2asH1styles>
            </TitleWrapper>
          )}
          <Content>
            <InfoContainer>
              <Title.H2>{subtitle}</Title.H2>
              <Text.Large semiBold>{description}</Text.Large>
            </InfoContainer>
            <ImageWrapper>
              <ImageSVG src={image} />
            </ImageWrapper>
          </Content>
        </Grid>
      </section>
    </Wrapper>
  )
}
export default DescriptionBlock
