import DescriptionBlock from './DescriptionBlock'
import React from 'react'
import session from '../../../static/img/family-seo/session-example.svg'

const HowWillBe = () => (
  <DescriptionBlock
    backgroundColor="#e9f2fd"
    description="Специалист уделит много внимания исследованию вашей семьи: в&nbsp;чем состоит проблема, как она проявляется, её истории, как развиваются отношения между членами семьи и т.д., объяснит как будет проходить работа. Также вы вместе поставите цели на&nbsp;терапию и сделаете первые маленькие шажочки к&nbsp;ее&nbsp;разрешению."
    image={session}
    subtitle="На&nbsp;первой консультации с&nbsp;семейным психологом "
    title="Как проходит онлайн-сессия с&nbsp;семейным психологом в&nbsp;YouTalk"
  />
)
export default HowWillBe
